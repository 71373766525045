import {
    Enum_Componentlinksproductlinks_Linksto,
    Enum_Forms_Type,
    Enum_Modules_Type,
    ModuleDisplayType,
} from '@graphql/generated/graphql';
import { NetworkName } from '@lib/share/types';
import { EventProperties } from '@segment/analytics-next';
import { AdditionalProductAction, AdditionalProductActionType } from '@types';
import { ValueOf } from 'next/dist/shared/lib/constants';

export enum EventTriggerSource {
    ONBOARDING = 'onboarding',
    MODULE = 'module',
}

export enum FlowContentType {
    FlowPage = 'Flow Page',
    Story = 'Story',
    Survey = 'Survey',
}

export enum OutboundLinkContext {
    Carousel = 'Carousel',
    StoreGrid = 'Store Grid',
    Header = 'Header',
    Footer = 'Footer',
    WelcomeScreen = 'Welcome Screen',
    ModuleList = 'Module List',
    ProductModal = 'Product Modal',
    NewsletterModal = 'Newsletter Modal',
    Modal = 'Modal',
    Article = 'Article',
    StoryPage = 'Story Page',
    RegistrationForm = 'Registration Form',
    Flow = 'Flow',
    SurveyPage = 'Survey Page',
}

export enum OutboundLinkShape {
    Card = 'Card',
    Visual = 'Visual',
    InlineText = 'Inline Text',
    Button = 'Button',
    Banner = 'Banner',
}

export enum FormContext {
    Flow = 'Flow',
    Onboarding = 'Onboarding',
    ProductPage = 'Product Page',
    Survey = 'Survey',
}

export enum FormShape {
    Modal = 'Modal',
    Page = 'Page',
    MultiPage = 'Multi Page',
}

export enum OutboundLinkDestinationCategory {
    Unknown = 'Unknown',
    AdditionalProduct = 'Additional Product',
    CompanyDocument = 'Company Document',
    SocialMedia = 'Social Media',
    PimsterDocument = 'Pimster Document',
}

export enum TrackEvent {
    // Modals
    ModalSubmitSuccess = 'Modal submit success',
    ModalSubmitFailure = 'Modal submit failure',

    // Forms
    FormError = 'Form Error',
    FormStarted = 'Form Started',
    FormSubmitted = 'Form Submitted',

    // Survey Pages
    SurveyPageView = 'Survey Page View',
    SurveySubmitted = 'Survey Submitted',
    SurveyDropOff = 'Survey Drop Off',

    // Page Views
    StoryPageView = 'Story Page View',

    ExitOnboarding = 'Exit Onboarding',

    // Component Interactions
    QuestionClicked = 'Question Clicked',
    LocalSwitched = 'Local Switched',
    StoryShareButtonClicked = 'Story Share Button Clicked',

    LockedModuleClick = 'Locked Module Click',

    // Consent
    ConsentRejected = 'Consent Rejected',
    ConsentAccepted = 'Consent Accepted',

    Search = 'Search',

    // Navigation
    OutboundLink = 'Outbound Link',
    FirstVisit = 'First Visit',

    // Session
    CompanyFirstVisit = 'Company First Visit',
    ProductFirstVisit = 'Product First Visit',

    // Performance
    PerformanceMetric = 'Performance Metric',

    // Flow
    FlowContentView = 'Flow Content View',
    FlowCompleted = 'Flow Completed',
    FlowDropOff = 'Flow Drop Off',
}

type DefaultEventPayload = EventProperties | null;

type OutboundLinkEventPayloadDefault = {
    category: 'click';
    destination: string;
    destination_name: string;
};

export type AdditionalProductOutboundLinkPayload =
    OutboundLinkEventPayloadDefault & {
        destination_category: OutboundLinkDestinationCategory.AdditionalProduct;
        shape:
            | OutboundLinkShape.Card
            | OutboundLinkShape.Banner
            | OutboundLinkShape.Button;
        context:
            | OutboundLinkContext.Carousel
            | OutboundLinkContext.StoreGrid
            | OutboundLinkContext.StoryPage
            | OutboundLinkContext.Modal
            | OutboundLinkContext.Flow;
        action?: AdditionalProductActionType;
        actionPayload?: ValueOf<AdditionalProductAction>;
    };

export type CompanyDocumentOutboundLinkPayload =
    OutboundLinkEventPayloadDefault & {
        destination_category: OutboundLinkDestinationCategory.CompanyDocument;
        shape: OutboundLinkShape.Visual | OutboundLinkShape.InlineText;
        context: OutboundLinkContext.Header | OutboundLinkContext.Footer;
    };

export type SocialMediaOutboundLinkPayload = OutboundLinkEventPayloadDefault & {
    destination_category: OutboundLinkDestinationCategory.SocialMedia;
    shape: OutboundLinkShape.Visual;
    context: OutboundLinkContext.Footer;
};

export type PimsterDocumentOutboundLinkPayload =
    OutboundLinkEventPayloadDefault & {
        destination_category: OutboundLinkDestinationCategory.PimsterDocument;
        shape: OutboundLinkShape.InlineText | OutboundLinkShape.Visual;
        context:
            | OutboundLinkContext.WelcomeScreen
            | OutboundLinkContext.ModuleList;
    };

export type ProductModalOutboundLinkPayload =
    OutboundLinkEventPayloadDefault & {
        destination_category: Enum_Componentlinksproductlinks_Linksto;

        shape: OutboundLinkShape.Card;
        context: OutboundLinkContext.ProductModal;
    };

export type UnknownOutboundLinkPayload = OutboundLinkEventPayloadDefault & {
    destination_category: OutboundLinkDestinationCategory.Unknown;
    shape:
        | OutboundLinkShape.Card
        | OutboundLinkShape.Button
        | OutboundLinkShape.InlineText
        | `${ModuleDisplayType}`;

    context:
        | OutboundLinkContext.Modal
        | OutboundLinkContext.ModuleList
        | OutboundLinkContext.Article
        | OutboundLinkContext.StoryPage;
};

/**
 * Payload
 */
export type OutboundLinkEventPayload =
    | AdditionalProductOutboundLinkPayload
    | CompanyDocumentOutboundLinkPayload
    | SocialMediaOutboundLinkPayload
    | PimsterDocumentOutboundLinkPayload
    | OutboundLinkEventPayloadDefault
    | ProductModalOutboundLinkPayload
    | UnknownOutboundLinkPayload;

type EventPayloadByEventName = {
    [TrackEvent.ModalSubmitSuccess]: { name: string; modal_uid: string };
    [TrackEvent.ModalSubmitFailure]: { name: string; modal_uid: string };
    [TrackEvent.FormError]: {
        name: string;
        form_uid: string;
        type: Enum_Forms_Type;
        context?: FormContext;
        shape: FormShape;
        error: string;
    };
    [TrackEvent.FormStarted]: {
        name: string;
        form_uid: string;
        type: Enum_Forms_Type;
        context?: FormContext;
        shape: FormShape;
    };
    [TrackEvent.FormSubmitted]: {
        name: string;
        form_uid: string;
        type: Enum_Forms_Type;
        context?: FormContext;
        shape: FormShape;
        data?: unknown;
    };
    [TrackEvent.StoryPageView]: {
        story_title: string;
        index: number;
        page_title?: string;
        video_duration: number;
        view_duration: number;
        loop_count: number;
        total_pages: number;
    };
    [TrackEvent.ExitOnboarding]: {
        current_step: number;
        total_steps: number;
    };
    [TrackEvent.QuestionClicked]: {
        question_name: string;
        positions: number;
        index?: string;
        query_id?: string;
    };
    [TrackEvent.LocalSwitched]: { from: string; to: string };
    [TrackEvent.StoryShareButtonClicked]: {
        story_slug: string;
        story_page_title?: string;
        story_page_number: number;
        is_liked_story: boolean;
        network: { name: NetworkName; config?: object };
    };
    [TrackEvent.LockedModuleClick]: {
        module_name: string;
        module_type: Enum_Modules_Type;
    };
    [TrackEvent.ConsentRejected]: Record<string, never>;
    [TrackEvent.ConsentAccepted]: Record<string, never>;
    [TrackEvent.Search]: {
        query: string;
        company_slug: string;
        product_slug: string;
        locale: string;
        searchResults: {
            index_name: string;
            index_type: string;
            hits_count: number;
            hits: {
                objectID: string;
                UID: string;
            }[];
        }[];
        total_hits_count: number;
    };
    [TrackEvent.OutboundLink]: OutboundLinkEventPayload;
    [TrackEvent.FirstVisit]: {
        company: string;
    };
    [TrackEvent.CompanyFirstVisit]: { company: string };
    [TrackEvent.ProductFirstVisit]: { company: string; product: string };
    [TrackEvent.PerformanceMetric]: {
        name: string;
        event_category: 'Web Vitals' | 'Next.js custom metric';
        value: number;
        event_label: string;
        non_interaction: boolean;
    };
    [TrackEvent.SurveyPageView]: {
        survey_slug: string;
        step_number: number;
        total_nb_steps: number;
        is_info_page: boolean;
        page_slug: string;
        trigger_source: EventTriggerSource;
    };
    [TrackEvent.SurveySubmitted]: {
        survey_slug: string;
        total_nb_steps: number;
        trigger_source: EventTriggerSource;
    };
    [TrackEvent.SurveyDropOff]: {
        survey_slug: string;
        step_number: number;
        total_nb_steps: number;
        page_slug: string;
        trigger_source: EventTriggerSource;
    };
    [TrackEvent.FlowContentView]: {
        flow_UID: string;
        step_number: number;
        total_steps: number;
        content_type: FlowContentType;
        content_slug: string;
        trigger_source: EventTriggerSource;
    };
    [TrackEvent.FlowCompleted]: {
        flow_UID: string;
        total_steps: number;
        trigger_source: EventTriggerSource;
    };
    [TrackEvent.FlowDropOff]: {
        flow_UID: string;
        step_number: number;
        total_steps: number;
        content_type: FlowContentType;
        content_slug: string;
        trigger_source: EventTriggerSource;
    };
};

export type EventPayload<K extends TrackEvent> =
    K extends keyof EventPayloadByEventName
        ? EventPayloadByEventName[K]
        : DefaultEventPayload;
