import { useTriggerEventContext } from '@components/utils/contexts/TriggerEventContext';
import LinkButton from '@components/utils/cta/linkButton';
import { HOME } from '@constants';
import styled from '@emotion/styled';
import {
    OutboundLinkContext,
    OutboundLinkDestinationCategory,
    OutboundLinkShape,
} from '@enums';
import {
    Enum_Modals_Trigger,
    EventCtaFragment,
    LinkCtaFragment,
    LockedContentCtaFragment,
    RedirectionType,
} from '@graphql/generated/graphql';
import { Button } from '@lib/uikit';
import { buildSlugFromUID } from '@utils/module/slug';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

interface Props {
    ctaComponent: EventCtaFragment | LinkCtaFragment | LockedContentCtaFragment;
    outboundLinkContext: OutboundLinkContext;
    onCloseModal?: () => void;
}

const CtaWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

export const RenderCtaComponent = ({
    ctaComponent,
    outboundLinkContext,
    onCloseModal,
}: Props) => {
    const { t } = useTranslation(HOME);
    const { onTriggerEvent } = useTriggerEventContext();
    const { query } = useRouter();

    switch (ctaComponent.__typename) {
        case 'ComponentCtaEventCallToAction':
            return (
                <CtaWrapper>
                    <Button
                        isFullWidth
                        buttonSize='large'
                        onClick={() => {
                            onCloseModal?.();
                            onTriggerEvent({
                                event: Enum_Modals_Trigger.CtaClick,
                                filters: { text: ctaComponent.Text },
                            });
                        }}
                    >
                        {ctaComponent.Text}
                    </Button>
                </CtaWrapper>
            );

        case 'ComponentCtaLinkCallToAction': {
            const isInternalLink =
                ctaComponent.Redirection_Type === RedirectionType.Inside;

            return (
                <CtaWrapper>
                    <LinkButton
                        isFullWidth
                        url={ctaComponent.Url}
                        text={ctaComponent.Text}
                        buttonSize='large'
                        onClick={() => onCloseModal?.()}
                        {...(isInternalLink
                            ? {
                                  type: RedirectionType.Inside,
                              }
                            : {
                                  type: RedirectionType.Outside,
                                  destinationName: ctaComponent.Text,
                                  shape: OutboundLinkShape.Button,
                                  destinationCategory:
                                      OutboundLinkDestinationCategory.Unknown,
                                  context:
                                      outboundLinkContext ||
                                      OutboundLinkContext.Modal,
                              })}
                    />
                </CtaWrapper>
            );
        }

        case 'ComponentCtaLockedContentCallToAction':
            return (
                <LinkButton
                    isFullWidth
                    type={RedirectionType.Inside}
                    url={`/${query.product}/flow/${buildSlugFromUID(
                        ctaComponent.RegistrationModule!.UID
                    )}`}
                    // @ts-ignore: CtaText is a GraphQL alias to prevent conflicts with the existing Text field but it causes a typing error
                    text={ctaComponent.CtaText || t('locked.modal.cta')}
                />
            );
    }
};
